import moment from "moment";

export function youtanEmailValidator(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email) && email.endsWith('@youtan.com.br');
}

export function getNumbers(text) {
  return text.match(/\d+/g).join('');
}

export function firstNames(names) {
  const splt = names.split(' ')
  return splt[0] + ' ' + splt[1]
}

export function utcToLocalObj(date) {
  const utc = moment.utc(date);
  const local = moment(utc).local();
  return local;
}

export function utcToLocal(date) {
  const utc = moment.utc(date);
  return moment(utc).local();
}

export function getWeekday(weekday, complete = false) {
  if (!complete) {
    switch (weekday) {
      case 'Monday':
        return 'Segunda';
      case 'Tuesday':
        return 'Terça';
      case 'Wednesday':
        return 'Quarta';
      case 'Thursday':
        return 'Quinta';
      case 'Friday':
        return 'Sexta';
      case 'Saturday':
        return 'Sábado';
      case 'Sunday':
        return 'Domingo';
      default:
        return weekday;
    }
  } else {
    switch (weekday) {
      case 'Monday':
        return 'Segunda-feira';
      case 'Tuesday':
        return 'Terça-feira';
      case 'Wednesday':
        return 'Quarta-feira';
      case 'Thursday':
        return 'Quinta-feira';
      case 'Friday':
        return 'Sexta-feira';
      case 'Saturday':
        return 'Sábado';
      case 'Sunday':
        return 'Domingo';
      default:
        return weekday;
    }
  }
}

export function getMonth(month) {
  switch (month) {
    case 'January':
      return 'Janeiro';
    case 'February':
      return 'Fevereiro';
    case 'March':
      return 'Março';
    case 'April':
      return 'Abril';
    case 'May':
      return 'Maio';
    case 'June':
      return 'Junho';
    case 'July':
      return 'Julho';
    case 'August':
      return 'Agosto';
    case 'September':
      return 'Setembro';
    case 'October':
      return 'Outubro';
    case 'November':
      return 'Novembro';
    case 'December':
      return 'Dezembro';
    default:
      return month;
  }
}

// get local date
export function currentDayString() {
  const utc = moment.utc();
  const local = moment(utc).local();
  const month = local.format('MMMM');
  const monthPt = getMonth(month);
  const weekday = local.format('dddd');
  const weekdayPt = getWeekday(weekday);

  return weekdayPt + ', ' + local.format('DD') + ' de ' + monthPt;
}

export function refDateString(dateRef) {
  const month = dateRef.format('MMMM');
  const monthPt = getMonth(month);
  const weekday = dateRef.format('dddd');
  const weekdayPt = getWeekday(weekday);

  return weekdayPt + ', ' + dateRef.format('DD') + ' de ' + monthPt;
}


export function isDateGreaterThan(date1, date2) {
  const utc1 = moment.utc(date1);
  const utc2 = moment.utc(date2);
  const local1 = moment(utc1).local();
  const local2 = moment(utc2).local();
  return local1.isAfter(local2);
}
