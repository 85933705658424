import { BsCheckCircleFill, BsQuestionOctagonFill } from 'react-icons/bs'
import { BiErrorAlt } from 'react-icons/bi'
import Backdrop from '../backdrop/Backdrop';


import './alert.css'

export default function CustomAlert(props) {

    function icon() {
        if (props.type === 'error') {
            return <BiErrorAlt style={{ color: '#d51c1c', fontSize: 235, margin: 0, marginTop: '10px' }} ></BiErrorAlt>
        }
        else if (props.type === 'ok') {
            return <BsCheckCircleFill style={{ color: '#5d9cb2', fontSize: 250, margin: 0, marginTop: '10px' }} ></BsCheckCircleFill>
        }
        else if (props.type === 'prompt') {
            return <BsQuestionOctagonFill style={{ color: '#5d9cb2', fontSize: 250, margin: 0, marginTop: '10px' }} ></BsQuestionOctagonFill>
        }
        return <div></div>
    }

    return (<Backdrop visible={props.visible} >

        <div className="customAlertDiv">
            {icon()}
            <p className='customAlertText' >
                {props.message}
            </p>
            {props.type !== 'prompt' ?
                <button onClick={props.onAccept} className={'customAlertBtn ' + (props.type !== 'error' ? 'blue' : 'red')}>ok</button>
                :
                <div className='customAlertButtonDiv'>
                    <button onClick={props.onAccept} className="customAlertBtn blue" >sim</button>
                    <button onClick={props.onRefuse} className="customAlertBtn borderlessRed" >cancelar</button>
                </div>
            }
        </div>

    </Backdrop>);
}