import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./pages/login/loginPage";
import Loading from "./components/loading/loading";
import Alert from "./components/alert/alert";
import ProtectedRoute from "./services/protected";
import SignupPage from "./pages/signup/signup";
import Home from "./pages/home/home";
import UserForm from "./pages/userForm/userform";
import AccountConfirm from "./pages/accountConfirm/accountConfirm";
import LinkDiscord from "./pages/linkDiscord/linkDiscord";

import { useSelector } from 'react-redux'

function App() {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("ok");
  const [alertOkFunction, setalertOkFunction] = useState(null);
  const [alertRefuseFunction, setAlertRefuseFunction] = useState(null);
  const loading = useSelector((state) => state.loading.loading);

  function showAlert(message, type='ok', okFunction=null, cancelFunction=null) {
    setAlertVisible(true);
    setAlertMessage(message);
    setAlertType(type);
    if (cancelFunction) {
      setAlertRefuseFunction(() => cancelFunction);
    } else {
      setAlertRefuseFunction(() => hideAlert);
    }
    if(okFunction) {
      setalertOkFunction(() => okFunction);
    } else {
      setalertOkFunction(() => hideAlert);
    }    
  }

  function hideAlert() {
    setAlertVisible(false);
  }

  function commonfunctions() {
    return {
      showAlert,
      hideAlert
    };
  }


    return (
    <div>
      <Loading visible={loading} />
      <Alert visible={alertVisible} message={alertMessage} type={alertType} onAccept={alertOkFunction} onRefuse={alertRefuseFunction}/>
      <Routes>
        <Route path="/" element={<LoginPage utils={commonfunctions()} /> } />
        <Route path="/signup" element={<SignupPage utils={commonfunctions()} /> } />
        <Route path="/home" element={<ProtectedRoute><Home utils={commonfunctions()} /></ProtectedRoute> } />
        <Route path="/profile" element={<ProtectedRoute><UserForm utils={commonfunctions()} /></ProtectedRoute> } />
        <Route path="/confirm/:code/:email" element={<AccountConfirm utils={commonfunctions()} /> } />
        <Route path="/discord/:user" element={<ProtectedRoute><LinkDiscord utils={commonfunctions()} /></ProtectedRoute> } />
      </Routes>
      
    </div>

  );
}

export default App;
