import React, { useState, useEffect } from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import useGetUsers from '../../services/hooks/useGetUsers';
import './userList.css';
import { setSelectedUsers } from '../../services/slices/selectionsSlice';
import UserInitialCircle from '../userInnitial/userinitial';
import { setUserListVisible } from '../../services/slices/userListSlice';
import { setEventsLoaded } from '../../services/slices/useEventsSlice';

export default function UserList() {

  const [filter, setFilter] = useState('');
  const [changed, setChanged] = useState(false);
  const [users, usersLoaded] = useGetUsers();
  const selectedUsers = useSelector((state) => state.selections.selectedUsers)
  const visible = useSelector((state) => state.userList.userListVisible);
  const title = useSelector((state) => state.userList.title);  

  const dispatch = useDispatch();

  const handleSelection = (user) => {
    if (selectedUsers.includes(user) === false) {
      dispatch(setSelectedUsers([...selectedUsers, user]));
      setChanged(true);
    } else {
      const newSelectedUsers = selectedUsers.filter((u) => u !== user);
      dispatch(setSelectedUsers(newSelectedUsers));
      setChanged(true);
    }
  };

  useEffect(() => {
    if (visible === false) {
      setChanged(false);
    }

  }, [users, selectedUsers, visible, usersLoaded])

  function isActive(user) {
    return selectedUsers?.includes(user) || false;
  }

  return (
    <Modal show={visible} centered dialogClassName='dialogSize'>
      <Modal.Body className="no-padding" >
        <div className='d-flex flex-column justify-content-center'>
          <p className='formTitle pt-2 pb-2'>{title || "Contatos"}</p>
          <input
            className='form-control mb-3 mt-3 mx-auto'
            placeholder='Pesquisar nome'
            style={{ 'maxWidth': '90%' }}
            onChange={(e) => setFilter(e.target.value)}
          >
          </input>
          <ListGroup className='contacts'>
            {users?.filter(x => x.name.toLowerCase().includes(filter.toLowerCase()))
              .map((user) => (
                <ListGroupItem
                  key={user.email}
                  active={isActive(user)}
                  onClick={() => handleSelection(user)}
                  action
                  className='nameSelected'
                >
                  <div className='d-flex align-items-center m-1  '>
                    <UserInitialCircle
                      name={user.name}
                      size={'small'}
                      photo={user.profile_picture}
                      allowUpload={false}
                      dropdown={false}
                      clic
                      id={user.email}
                    ></UserInitialCircle>
                    <p className='imgMargin'>
                      {user.name}
                    </p>
                  </div>
                </ListGroupItem>
              ))}
          </ListGroup>
          <button onClick={() => {
            dispatch(setUserListVisible(false))
            if (changed) {
              dispatch(setEventsLoaded(false))
            }
          }} className='btnBlue formBtnSize mt-4'>ok</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};