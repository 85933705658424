import { createSlice } from "@reduxjs/toolkit";

export const LoadingSllce = createSlice({
    name: "loading",
    initialState: {
        loading: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
    },
});

export const { setLoading } = LoadingSllce.actions;

export default LoadingSllce.reducer;