import { createSlice } from '@reduxjs/toolkit'

export const viewStyleSlice = createSlice({
    name: 'viewStyle',
    initialState: {
        style : 'day',
    },
    reducers: {
        setStyle: (state, action) => {
            state.style = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setStyle } = viewStyleSlice.actions

export default viewStyleSlice.reducer