import { createSlice } from "@reduxjs/toolkit";

export const useEventsSlice = createSlice({
    name: "useEvents",
    initialState: {
        eventsLoaded: false,
        events: [],
    },
    reducers: {
        setEventsLoaded: (state, action) => {
            state.eventsLoaded = action.payload;
        },
        setEvents: (state, action) => {
            state.events = action.payload;
        },
    },
});

export const { setEventsLoaded, setEvents } = useEventsSlice.actions;

export default useEventsSlice.reducer;