import axios from 'axios';

// export const baseURL = "https://calendario-api.savietto.app/";
export const baseURL = window.location.origin.replace(".", "-api.") 

export default axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});
    