import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedUser } from '../../services/slices/selectionsSlice';
import useAxios from '../../services/hooks/useAxios';
import { setLoading } from '../../services/slices/loadingSlice';
import { setTokens } from '../../services/slices/authSlice';

export default function LoginPage({ utils }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const axios = useAxios();

  const accToken = useSelector((state) => state.auth.accessToken);

  useEffect(() => {
    if (accToken) {
      // console.log('indo para home')
      navigate('/home');
    }
  }, [navigate, accToken]);

  function submitForm(e) {
    e.preventDefault();
    const data = new FormData(e.target);

    dispatch(setLoading(true));

    axios.post('users/login', data, { headers: { 'Content-Type': 'www-form-urlencoded' } }).then(res => {
      dispatch(setLoading(false));
      dispatch(setTokens({ accessToken: res.data.access_token, refreshToken: res.data.refresh_token }));
      dispatch(setLoggedUser(res.data.profile));
      navigate('/home');
      
    }).catch(err => {
      console.log(err)
      if (err.response.status === 401) {
        dispatch(setLoading(false));
        utils.showAlert('Usuário ou senha inválidos', 'error');
      } else {
        dispatch(setLoading(false));
        utils.showAlert('Erro ao realizar login', 'error');
      }
    }
    );

  }

  return (
    <div className='whiteBackground'>
      <div className='box centralizeItems centerscreen boxshadow'>
        <p className='formTitle'> Login na plataforma </p>
        <Form onSubmit={submitForm} className='formLimit'>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control required name='username' placeholder="Insira seu e-mail institucional" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>Senha</Form.Label>
            <Form.Control required name='password' type="password" placeholder="Insira sua senha" />
          </Form.Group>
          <div className='centralizeItems'>
            <button type='submit' className='btnBlue formBtnSize'>Login</button>
            <button onClick={() => { navigate('/signup') }} className='btnBorderlessBlue formBtnSize' >Não tenho uma conta</button>
          </div>
        </Form>
      </div>
    </div>
  );
}
