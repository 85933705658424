import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux'

export default function ProtectedRoute(props) {

    const accToken = useSelector((state) => state.auth.accessToken);
    const loggedUser = useSelector((state) => state.selections.loggedUser)

    function checkAuth() {
        // console.log(verified)
        if (accToken) {
            // console.log(token)
            // console.log('valor de verified', verified)
            if (loggedUser?.verified === 'false') {
                console.log('conta inativa')
                return <Navigate to={'/confirm/unverified' }/>;
            }
            return props.children;
        }
        return <Navigate to={'/' }/>;
    }

    return (
        <div>
            {checkAuth()}
        </div>
        
    );
}
