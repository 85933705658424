import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import useAxios from '../../services/hooks/useAxios';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../services/slices/loadingSlice';

export default function LinkDiscord({ utils }) {

    const navigate = useNavigate();
    const { user } = useParams();
    const dispatch = useDispatch();
    const axios = useAxios();

    useEffect(() => {
        dispatch(setLoading(true));
        // send confirmation code to backend        
        const access_token = localStorage.getItem('access_token');
        if (!access_token) {
            // console.log('token não existe')
            dispatch(setLoading(false));
            utils.showAlert('Faça login antes de se conectar com o Discord!', 'ok', () => { 
                utils.hideAlert();
                navigate('/') 
            });
        } else {
            // // console.log(code)
            // // console.log(email)
            // console.log('user is: ' + user)
            axios.put('users/discord', {discord_user: user}).then(res => {
                // console.log('link feito')
                dispatch(setLoading(false));
                utils.showAlert('A sua conexão com o Discord foi concluída', 'ok', () => {
                    utils.hideAlert();
                    navigate('/home') 
                });
            }).catch(err => {
                // console.log(err)
                // console.log('catch')
                if (err.response.status) {
                    // // console.log('status')
                    if (err.response.status === 404) {
                        utils.showAlert('Usuário inválido', 'error', () => { 
                            utils.hideAlert();
                            navigate('/')                     
                    });
                    } else {
                        dispatch(setLoading(false));
                        navigate('/');
                    }
                } else {
                    // console.log('caiu nesse caso')
                    utils.showAlert('Ocorreu um erro. Tente mais tarde', 'error', () => { 
                        utils.hideAlert();
                        navigate('/') 
                });
                }
            }

            );
        }

    });

    return (
        <div className='limeBackground'>

        </div>
    );
}