import { useEffect } from "react";
import useAxios from "./useAxios";
import { useSelector, useDispatch } from 'react-redux'
import { setLoading } from "../slices/loadingSlice";
import { useNavigate } from "react-router-dom";
import { setLoggedUser } from "../slices/selectionsSlice";

export default function useGetUserProfile() {

    const dispatch = useDispatch();
    const loggedUserRedux = useSelector((state) => state.selections.loggedUser)
    const navigate = useNavigate();
    const axios = useAxios();
     
    useEffect(() => {
        if (!loggedUserRedux) {
            dispatch(setLoading(true));
            if (!localStorage.getItem('email')) {
                dispatch(setLoading(false));
                localStorage.clear();
                navigate('/');
                return;
            }
            axios.get('/users/profile', { params: { username: localStorage.getItem('email'), pictures: true } }).then((response) => {
                dispatch(setLoggedUser(response.data));
            }
            ).catch((error) => {

            })    
        }
    }, [loggedUserRedux, axios, dispatch, navigate])

    return [loggedUserRedux];
}
