import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { getWeekday } from '../../services/functions';
import { useSelector, useDispatch } from 'react-redux';
import { setEdit, setScheduleFormVisible } from '../../services/slices/scheduleFormSlice';
import { setUserListVisible, setTitle } from '../../services/slices/userListSlice';
import useAxios from '../../services/hooks/useAxios';
import { setEventsLoaded } from '../../services/slices/useEventsSlice';
import { setLoading } from '../../services/slices/loadingSlice';
import UserInitialCircle from '../userInnitial/userinitial';
import useGetUsers from '../../services/hooks/useGetUsers';
import DeleteConfirmForm from '../deleteConfirmForm/deleteConfirmForm';
import { setDeleteModalVisible } from '../../services/slices/deleteModalSlice';

import { setSelectedAppointment, setSelectedUsers } from '../../services/slices/selectionsSlice';

export default function ScheduleFormModal({ alertFunction, hideAlert }) {

    const [formTitle, setFormTitle] = useState('Novo agendamento');
    const [appointmentName, setAppointmentName] = useState('');
    const [appointmentLink, setAppointmentLink] = useState('');
    const [appointmentRepeatMode, setAppointmentRepeatMode] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [formValid,] = useState(false);
    const [users, usersLoaded] = useGetUsers();
    const [changedStartDate, setChangedStartDate] = useState(false);
    const [changedEndDate, setChangedEndDate] = useState(false);
    const [viewOnly, setViewOnly] = useState(false);
    const [isMember, setIsMember] = useState(false);
    const axios = useAxios();

    const [originalSelectedUSers, setoriginalSelectedUSers] = useState(undefined);

    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.selections.loggedUser);
    const selectedDate = useSelector((state) => state.selections.selectedDate);
    const selectedUsers = useSelector((state) => state.selections.selectedUsers);
    const selectedAppointment = useSelector((state) => state.selections.selectedAppointment);
    const visible = useSelector((state) => state.scheduleForm.visible);
    const userListVisible = useSelector((state) => state.userList.userListVisible);
    const loadingVisible = useSelector((state) => state.loading.loading);

    const edit = useSelector((state) => state.scheduleForm.edit);
    // implementar botão de salvar, fechar, editar e criar novo agendamento

    useEffect(() => {
        const dt = moment(selectedDate);
        if (!usersLoaded) {
            return;
        }
        if (selectedAppointment) {
            // console.log(selectedAppointment)
            if (selectedAppointment.creator !== loggedUser.email) {
                setViewOnly(true);
                const creatorFirstName = selectedAppointment.creator_name.split(' ')[0];
                setFormTitle('Agendamento de ' + creatorFirstName);
                if (selectedAppointment.participants.includes(loggedUser.email)) {
                    setIsMember(true);
                }
            } else {
                setFormTitle('Seu agendamento');
            }
            setStartDate(selectedAppointment.start_date);
            setEndDate(selectedAppointment.end_date);
            if (originalSelectedUSers === undefined && visible === true) {
                var apUsers = users.filter((user) => selectedAppointment.participants.includes(user.email));
                if (selectedAppointment.participants.includes(loggedUser.email) === true) {
                    apUsers.push(loggedUser);
                }
                setoriginalSelectedUSers(selectedUsers);
                dispatch(setSelectedUsers(apUsers))
            }
        } else {
            if (dt.hour() !== moment(selectedDate).hour()) {
                setStartDate(dt.format('HH:mm d'));
            }
            setFormTitle('Novo agendamento');
        }
        // eslint-disable-next-line
    }, [selectedAppointment, selectedDate, userListVisible, usersLoaded, viewOnly])

    function restoreSelectedUsers() {
        if (edit === true) {
            dispatch(setSelectedUsers(originalSelectedUSers || []));
            setoriginalSelectedUSers(undefined);
        } else {
            dispatch(setSelectedUsers([]));
        }
    }

    function resetStates() {
        setAppointmentName('');
        setAppointmentLink('');
        setAppointmentRepeatMode(0);
        setStartDate('');
        setEndDate('');
        restoreSelectedUsers();
        setChangedStartDate(false);
        setChangedEndDate(false);
        setViewOnly(false);
        setFormTitle('Novo agendamento');
        setIsMember(false);
    }

    function to_iso(date) {
        const dt = moment(selectedDate);
        dt.set('hour', date.split(':')[0]);
        dt.set('minute', date.split(':')[1]);
        dt.set('second', 0)
        return dt.utc().toISOString();
    }
    function formSubmit(e) {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            return;
        }
        const data = new FormData(e.target);
        var datadict = Object.fromEntries(data.entries());
        const s = selectedUsers || [loggedUser]
        datadict.participants = s.map((user) => user.email || user).concat([loggedUser.email]);
        datadict.start_date = to_iso(datadict.start_date);
        datadict.end_date = to_iso(datadict.end_date);
        datadict.repeat_mode = datadict.repeat_mode === '' ? null : datadict.repeat_mode;
        datadict.date_threshold = moment().utc().toISOString();

        if (datadict) {
            dispatch(setLoading(true));
            if (edit === false) {
                alertFunction("Você tem certeza que deseja fazer esse agendamento?", "prompt", () => {
                    dispatch(setLoading(true));
                    hideAlert();
                    axios.post('appointments/', datadict).then((res) => {
                        dispatch(setScheduleFormVisible(false));
                        dispatch(setEventsLoaded(false));
                        resetStates();
                        dispatch(setLoading(false));
                    }).catch((error) => {
                        hideAlert();
                        dispatch(setLoading(false));
                        alertFunction(error.response.data.detail || "Erro ao criar agendamento", "error");
                    });
                }, () => { 
                    dispatch(setLoading(false));
                    hideAlert();
                    dispatch(setScheduleFormVisible(true)); 
                });
            } else {
                datadict.id = selectedAppointment.id;
                alertFunction("Você tem certeza que deseja editar esse agendamento?", "prompt", () => {
                    dispatch(setLoading(true));
                    hideAlert();
                    axios.put('appointments/', datadict).then((res) => {
                        // dispatch(setScheduleFormVisible(false));
                        dispatch(setScheduleFormVisible(false));
                        dispatch(setEventsLoaded(false));
                        resetStates();
                        dispatch(setLoading(false));
                    }).catch((error) => {
                        dispatch(setLoading(false));
                        alertFunction(error.response.data.detail || "Erro ao editar agendamento", "error", () => { dispatch(setScheduleFormVisible(false)) });
                    });
                });
            }
        }
    }

    function leave_appointment(e) {
        e.preventDefault();
        alertFunction("Você tem certeza que deseja sair deste agendamento?", "prompt", () => {
            dispatch(setLoading(true));
            hideAlert();
            axios.delete('appointments/participation/' + selectedAppointment.id).then((res) => {
                dispatch(setScheduleFormVisible(false));
                dispatch(setEventsLoaded(false));
                resetStates();
                dispatch(setLoading(false));
            }).catch((error) => {
                alertFunction(error.response.data.detail || "Erro ao sair do agendamento", "error");
            });
        }, () => { 
            dispatch(setLoading(false));
            hideAlert();
            dispatch(setScheduleFormVisible(true)); 
        });
    }

    function defaultStart() {
        if (startDate === '') {
            var dt = undefined;
            if (selectedAppointment) {
                dt = moment(selectedAppointment.start_date)
            } else {
                dt = moment(selectedDate)
                if (changedStartDate === false) {
                    dt.set('minute', 0);
                }
            }
            return dt.format('HH:mm');
        }
        return startDate;
    }

    function defaultEnd() {
        if (endDate === '') {
            var dt = undefined;
            if (selectedAppointment) {
                dt = moment(selectedAppointment.end_date)
            } else {
                dt = moment(selectedDate).add(1, 'hour')
                if (changedEndDate === false) {
                    dt.set('minute', 0);
                }
            }
            return dt.format('HH:mm');
        }
        return endDate;
    }

    function deleteconfirmation() {
        alertFunction("Você tem certeza que deseja excluir este agendamento?", "prompt", () => {
            hideAlert();
            resetStates();
            if ( selectedAppointment?.parent_appointment !== null || selectedAppointment?.has_related === true){
                dispatch(setScheduleFormVisible(false));
                dispatch(setDeleteModalVisible(true));            
                
            } else {
                dispatch(setLoading(true));
                axios.delete('appointments/' + selectedAppointment.id, { params: { 'delete_strategy': 'this' } }).then((res) => {
                    dispatch(setLoading(false));
                    dispatch(setScheduleFormVisible(false));
                    dispatch(setEventsLoaded(false));
                }).catch((error) => {
                    dispatch(setLoading(false));
                    alertFunction(error.response.data.detail || "Erro ao excluir agendamento", "error");
                });
            }
        });
    }

    function weekdayName() {
        const dt = moment(selectedDate);
        return getWeekday(dt.format('dddd'), true);
    }

    function onRefuse(e) {
        e.preventDefault();
        restoreSelectedUsers();
        dispatch(setScheduleFormVisible(false));
        dispatch(setSelectedAppointment(undefined));
        dispatch(setEdit(false));
        resetStates();
    }

    return (
        <div>
            <DeleteConfirmForm/>
            <Modal show={visible && !userListVisible && !loadingVisible} centered dialogClassName='dialogSize'>
                <Modal.Body className="no-padding p-1 pb-4" >
                    <div className='d-flex flex-column justify-content-center'>
                        <p className='formTitle pt-2'>{formTitle || "Contatos"}</p>
                        <Form onSubmit={(e) => {
                                if (viewOnly === true){
                                    e.preventDefault()
                                } else {
                                    formSubmit(e)
                                }
                            }} 
                            className='m-3 mt-0 mb-0' validated={formValid}>
                            <Form.Group className="mb-1" controlId="formGroupEmail">
                                <Form.Label>Nome do agendamento</Form.Label>
                                <Form.Control
                                    required
                                    readOnly={viewOnly}
                                    name='description'
                                    type="text"
                                    placeholder="Reunião muito importante"
                                    defaultValue={selectedAppointment ? selectedAppointment.description : appointmentName}
                                    onChange={(e) => { setAppointmentName(e.target.value) }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="formGroupEmail">
                                <Form.Label>Link de Reunião (opcional)</Form.Label>
                                <Form.Control
                                    name='meeting_link'
                                    readOnly={viewOnly}
                                    type="text"
                                    placeholder="https://seu-link.com"
                                    defaultValue={selectedAppointment ? selectedAppointment.meeting_link : appointmentLink}
                                    onChange={(e) => { setAppointmentLink(e.target.value) }}

                                />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="formGroupEmail">
                                <Form.Label>Horário de início</Form.Label>
                                <Form.Control
                                    defaultValue={defaultStart()}
                                    required
                                    readOnly={viewOnly}
                                    name='start_date'
                                    type="time"
                                    placeholder="10:00"
                                    onChange={(e) => {
                                        setStartDate(e.target.value)
                                        setChangedStartDate(true);
                                    }}
                                    isInvalid={startDate < moment().format('HH:mm') && startDate !== '' && selectedDate < moment()}
                                    isValid={startDate >= moment().format('HH:mm')}
                                />
                                <Form.Control.Feedback type="invalid">
                                    O horário de início deve ser após o horário atual
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="formGroupPassword">
                                <Form.Label>Horário de término</Form.Label>
                                <Form.Control
                                    required
                                    defaultValue={defaultEnd()}
                                    readOnly={viewOnly}
                                    name='end_date'
                                    type="time"
                                    placeholder="11:00"
                                    onChange={(e) => {
                                        setEndDate(e.target.value)
                                        setChangedEndDate(true);
                                    }}
                                    isValid={endDate > startDate}
                                    isInvalid={endDate <= startDate && endDate !== ''}
                                />
                                <Form.Control.Feedback type="invalid">
                                    O horário de término deve ser após o horário de início
                                </Form.Control.Feedback>
                            </Form.Group>
                            {!edit ?
                                <Form.Group>
                                    <Form.Label>Recorrencia</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name='repeat_mode'
                                        defaultValue={appointmentRepeatMode || 0}
                                        onChange={(e) => { setAppointmentRepeatMode(e.target.value) }}
                                        readOnly={viewOnly}
                                    >
                                        <option value={''}>Não recorrente</option>
                                        <option value='week'>Dias úteis dessa semana</option>
                                        <option value='weekday_month'>Sempre de {weekdayName()} neste mês</option>
                                        <option value='daily_month'>Dias úteis deste mês</option>
                                    </Form.Control>
                                </Form.Group>
                                : <div />}
                            {viewOnly === false &&
                                <p
                                    onClick={() => {
                                        dispatch(setTitle('Participantes'));
                                        dispatch(setUserListVisible(true));
                                    }}
                                    className={'blueLink ' + (edit === true ? 'mt-2' : 'mt-2 mb-0')}>
                                    Gerenciar convidados
                                </p>
                            }
                            <div className='d-flex mx-auto align-content-center justify-content-center mb-2 mt-1'>
                                {
                                    selectedUsers.length === 0 ?
                                        <p className='m-0'></p>
                                        :
                                        selectedUsers.map((user) => {
                                            return (
                                                <div key={user.email} className='m-1'>
                                                    <UserInitialCircle
                                                        name={user.name}
                                                        photo={user.profile_picture}
                                                        size='small'
                                                    />
                                                </div>
                                            )
                                        })
                                }
                            </div>
                            {edit === true && viewOnly === false &&
                                <p onClick={deleteconfirmation} className='mb-3 redLink'>Deletar</p>
                            }

                            <div className='d-flex mx-auto '>

                                {viewOnly === false ?
                                    <div className='d-flex w-100'>
                                        <button type='submit' className='btnBlue formBtnSize m-1 mb-0 mt-0'>salvar</button>
                                        <button type='button' onClick={onRefuse} className='btnBorderlessRed formBtnSize m-1 mb-0 mt-0'>fechar</button>
                                    </div>
                                    :
                                    <div className='d-flex w-100 align-content-center mx-auto'>
                                        <button type='button' onClick={onRefuse} className='btnBlue formBtnSize p-0 m-0 mx-auto'>fechar</button>
                                        {isMember === true &&
                                            <button type='button' onClick={leave_appointment} className='btnRed formBtnSize p-0 m-1 mb-0 mt-0'>cancelar participação</button>
                                        }
                                        <button type='submit' className='d-none '> </button>
                                    </div>
                                }
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};