import { createSlice } from '@reduxjs/toolkit'

export const scheduleFormSlice = createSlice({
    name: 'scheduleForm',
    initialState: {
        visible: false,
        edit: false,
    },
    reducers: {
        setScheduleFormVisible: (state, action) => {
            state.visible = action.payload
        },
        setEdit: (state, action) => {
            state.edit = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setScheduleFormVisible, setEdit } = scheduleFormSlice.actions

export default scheduleFormSlice.reducer