import React from 'react';
import { Modal } from 'react-bootstrap';

function Backdrop(props) {
  return (
    <Modal show={props.visible} onHide={props.onClose} centered>
      {props.children}
    </Modal>
  );
}

export default Backdrop;