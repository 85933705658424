import { BsCalendar3 } from 'react-icons/bs';
import { FiList } from 'react-icons/fi';
import { FaUsers } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux'
import { setStyle } from '../../services/slices/viewStyleSlice';

import { useState, useEffect } from 'react';

import './calendarButtons.css';

export default function CalendarButtons(props){

    const [view, setView] = useState(props.view || 'day');
    
    const dispatch = useDispatch();    
    const viewStyle = useSelector((state) => state.viewStyle.style);    

    useEffect(() => {
        if (view !== viewStyle){
            setView(viewStyle);
        }
    }, [props.view, viewStyle, view])


    function buttonType(style){
        if(view === 'day'){
            return <BsCalendar3 title={'Visualizar mês'} onClick={changeView} className='iconSize'/>
        } else if(view === 'month'){
            return <FiList title={'Visualizar dia'} onClick={changeView} className='iconSize'/>
        }
    }

    function changeView(){
        if(view === 'day'){
            setView('month');
            dispatch(setStyle('month'));
            
        } else {
            setView('day');
            dispatch(setStyle('day'));
        }       
    }

    useEffect(() => {
    }, [view])
        

    return (
        <div>
            <div className='d-flex justify-content-between'>                
                {/* <div> */}                    
                    <FaUsers title={'Lista de contatos'} onClick={props.usersClick} className='iconSize mx-3'/>
                    {buttonType()}
                {/* </div> */}
            </div>
        </div>
    )
}