import { useEffect } from "react";
import useAxios from './useAxios'
import { useSelector, useDispatch } from 'react-redux'
import moment from "moment";
import { setEventsLoaded, setEvents } from "../slices/useEventsSlice";
import { setLoading } from "../slices/loadingSlice";

export default function useGetEvents() {

    const dispatch = useDispatch();
    const loggedUserRedux = useSelector((state) => state.selections.loggedUser)
    const selectedUsersRedux = useSelector((state) => state.selections.selectedUsers)
    const selectedDateRedux = useSelector((state) => state.selections.selectedDate)
    const eventsLoaded = useSelector((state) => state.events.eventsLoaded)
    const events = useSelector((state) => state.events.events)
    const axios = useAxios();
     
    useEffect(() => {
        if (!eventsLoaded && loggedUserRedux) {
            dispatch(setEvents([]));
            dispatch(setLoading(true));
            const usersFilter = selectedUsersRedux.map((user) => user.email).join(',') + ',' + loggedUserRedux.email;
            const dt = moment(selectedDateRedux).toDate();
            const year = dt.getFullYear();
            const month = dt.getMonth() + 1;
            axios.get('/appointments/list', { params: { users: usersFilter, month: month, year: year } })
                .then((response) => {
                    dispatch(setLoading(false));
                    var copy = JSON.parse(JSON.stringify(response.data));
                    copy.forEach((person) => {
                        person.appointments.forEach((appointment) => {
                            appointment.start_date = moment.utc(appointment.start_date).local().toISOString();
                            appointment.end_date = moment.utc(appointment.end_date).local().toISOString();
                        })
                    })
                    dispatch(setEvents(copy));
                    dispatch(setEventsLoaded(true));
                }).catch((error) => {
                    dispatch(setLoading(false));
                    dispatch(setEventsLoaded(true));
                })
        }
    }, [loggedUserRedux,axios, eventsLoaded, selectedUsersRedux, selectedDateRedux, dispatch])

    return [events, eventsLoaded];
}
