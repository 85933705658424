import { useState, useEffect } from "react";
import useAxios from "./useAxios";
import { useSelector, useDispatch } from 'react-redux'
import { setUsersStore } from "../slices/userListSlice";
import { setLoading } from "../slices/loadingSlice";

export default function useGetUsers() {

    const [users, setUsers] = useState(undefined);
    const [usersLoaded, setUsersLoaded] = useState(false);
    const [noData, setNoData] = useState(false);
    const usersRedux = useSelector((state) => state.userList.users)
    const loggedUserRedux = useSelector((state) => state.selections.loggedUser)
    const dispatch = useDispatch();
    const axios = useAxios();
    
    useEffect(() => {        
        if (noData === true){
            return
        }
        setUsersLoaded(false);
        if (usersRedux.length > 0) {            
            setUsers(usersRedux.filter((user) => user.email !== loggedUserRedux?.email)
            .sort((a, b) => {
                return a.name.localeCompare(b.name)
            }));
            setUsersLoaded(true);
        } 
        else {
            dispatch(setLoading(true));
            axios.get('/users/list').then((response) => {
                dispatch(setLoading(false));
                const u = response.data.filter((user) => user.email !== loggedUserRedux?.email)
                .sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
                setUsers(u);
                dispatch(setUsersStore(u));
                if (u.length === 0){
                    setNoData(true);
                }
            }).catch((error) => {
                console.log(error);
                dispatch(setLoading(false));
            })                
        }
    }, [dispatch, axios, usersLoaded, loggedUserRedux, usersRedux])

    return [users, usersLoaded];
}
