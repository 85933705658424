import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

export const selectionsSlice = createSlice({
    name: 'selections',
    initialState: {
        loggedUser: localStorage.getItem('loggedUser') ? JSON.parse(localStorage.getItem('loggedUser')) : undefined,
        currentDate: moment().format('YYYY-MM-DD HH:mm'),
        selectedUsers: [],
        selectedDate: moment().format('YYYY-MM-DD HH:mm'),
        selectedAppointment: undefined,
    },
    reducers: {
        setLoggedUser: (state, action) => {
            localStorage.setItem('loggedUser', JSON.stringify(action.payload))
            state.loggedUser = action.payload
        },
        setCurrentDate: (state, action) => {
            state.currentDate = action.payload
        },
        setSelectedUsers: (state, action) => {
            state.selectedUsers = action.payload
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload
        },
        setSelectedAppointment: (state, action) => {
            state.selectedAppointment = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setLoggedUser, setCurrentDate, setSelectedUsers, setSelectedDate, setSelectedAppointment } = selectionsSlice.actions

export default selectionsSlice.reducer