import { useNavigate, useParams } from 'react-router-dom';
import { useEffect} from 'react';
import useAxios from '../../services/hooks/useAxios';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../services/slices/loadingSlice';

export default function AccountConfirm({utils}) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { code, email } = useParams();
    const axios = useAxios();

    useEffect(() => {
        dispatch(setLoading(true));
        if (!code || code === 'unverified') {
            dispatch(setLoading(false));
            utils.showAlert('Verifique seu e-mail para utilizar a plataforma', 'ok', () => { 
                utils.hideAlert();
                navigate('/') 
            });
        } else {
            axios.post('users/verify_email', { email: email, code: code }).then(res => {
                dispatch(setLoading(false));
                // localStorage.setItem('verified', 'true');
                utils.showAlert('Conta confirmada com sucesso', 'ok', () => {
                    utils.hideAlert();
                    navigate('/home') 
                });
            }).catch(err => {
                if (err.response.status) {
                    if (err.response.status === 400) {
                        utils.showAlert('Código inválido', 'error', () => { 
                            utils.hideAlert();
                            navigate('/')                     
                    });
                    } else {
                        utils.hideAlert()
                        navigate('/');
                    }
                } else {
                    utils.showAlert('Ocorreu um erro. Tente mais tarde', 'error', () => { 
                        utils.hideAlert();
                        navigate('/') 
                });
                }
            }

            );
        }

    }, [axios, code, dispatch]);

    return (
        <div className='limeBackground'>

        </div>
    );
}