import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from './services/slices/authSlice';
import userListSlice from './services/slices/userListSlice';
import viewStyleSlice from './services/slices/viewStyleSlice';
import selectionsSlice from './services/slices/selectionsSlice';
import scheduleFormSlice from './services/slices/scheduleFormSlice';
import useEventsSlice from './services/slices/useEventsSlice';
import loadingSlice from './services/slices/loadingSlice';
import deleteModalSlice from './services/slices/deleteModalSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  userList: userListSlice,
  viewStyle: viewStyleSlice,
  selections: selectionsSlice,
  scheduleForm: scheduleFormSlice,
  events: useEventsSlice,
  loading: loadingSlice,
  deleteModal: deleteModalSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  // devtools: true
});

export default store;
