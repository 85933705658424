import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getMonth } from '../../services/functions';
import Badge from 'react-bootstrap/Badge';
import useGetEvents from "../../services/hooks/useGetEvents";
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedDate } from '../../services/slices/selectionsSlice';
import { setStyle } from '../../services/slices/viewStyleSlice';
import { setEventsLoaded } from '../../services/slices/useEventsSlice';
import ArrowButton from '../arrowBtn/arrowBtn';

import './calendar.css';

const Calendar = ({ refreshCallback, children }) => {
  const selectedDate = useSelector((state) => state.selections.selectedDate);
  const [viewDate, setViewDate] = useState(moment().format('MMMM YYYY'));
  const [title, setTitle] = useState('');
  const [events, eventsLoaded, ] = useGetEvents();

  const dispatch = useDispatch();

  function handleMonthChange(day, month, year, monthDelta){
    const date = moment(viewDate);
    if(day){
      date.date(day);
    }
    if(month){
      date.month(month - 1);
    }
    if(year){
      date.year(year);
    }
    if(monthDelta){
      date.add(monthDelta, 'month');
    }
    dispatch(setSelectedDate(date.format('YYYY-MM-DD HH:mm')));
    if (refreshCallback) {
      refreshCallback(date);
    }
    dispatch(setEventsLoaded(false));
  }

  const renderDays = () => {
    const monthStart =  moment(viewDate).clone().startOf('month');
    const monthEnd =  moment(viewDate).clone().endOf('month');
    const startWeek = monthStart.week();
    var endWeek = monthEnd.week();
    var renderLast = false

    const weeks = [];

    if (startWeek > endWeek && endWeek === 1) {
      endWeek = monthEnd.subtract(1, 'week').week();
      renderLast = true
    }

    for (let week = startWeek; week <= endWeek; week++) {
      weeks.push(
        <div className="week" key={week}>
          {renderWeek(week)}
        </div>
      );
    }

    if (renderLast) {
      weeks.push(
        <div className="week" key={endWeek + 1}>
          {renderWeek(endWeek + 1)}
        </div>
      );
    }
    return weeks;
  };

  const renderWeek = week => {
    const days = [];
    const ap = events?.map((userEvents) => userEvents.appointments).flat() || [];    

    const weekStart =  moment(viewDate)
      .clone()
      .week(week)
      .startOf('week');

    for (let i = 0; i < 7; i++) {
      const day = weekStart.clone().add(i, 'day');
      const isCurrentMonth = day.month() === moment(viewDate).month();
      var className = isCurrentMonth ? 'day' : 'day other-month';
      if (day.isSame(new Date(), 'day')) {
        className += ' today';
      }
      // check if day is weekend
      if (day.day() === 0 || day.day() === 6) {
        className += ' weekend';
      }
      const appointmentsToday = ap.filter(appointment => moment.utc(appointment.start_date).local().format('YYYY-MM-DD') === day.format('YYYY-MM-DD'));
      // var badgeText = '';
      // if (appointmentsToday.length === 1) {
      //   badgeText = '1 agendamento'
      // } else if (appointmentsToday.length > 1) {
      //   badgeText = appointmentsToday.length + ' agendamentos'
      // }

      days.push(
        <div onClick={(e) => dayClicked(e, day.format('D'), day.format('M'), day.format('Y'))} className={className} key={day.format('DD')}>

          <p>
            {day.format('D')}
          </p>
          <div>
            {appointmentsToday.length > 0 && <Badge pill bg="secondary">{appointmentsToday.length}</Badge>}
          </div>
        </div>
      );
    }

    return days;
  };

  function dayClicked(event, day, month, year) {
    // check if component is a span
    if (event.target.tagName === 'SPAN') {
      return;
    }
    const date = moment(viewDate);
    date.date(day);
    date.month(month - 1);
    date.year(year);    
    handleMonthChange(day, month, year);
    dispatch(setStyle('day'));
  }

  useEffect(() => {
    const current = moment(selectedDate);
    const selected = moment(viewDate);
    setTitle(getMonth(moment(viewDate).format('MMMM')) + ' de ' + moment(viewDate).year());
    if (current.format('YYYY-MM') !== selected.format('YYYY-MM')) {
      setViewDate(moment(selectedDate));
    }

  }, [viewDate, selectedDate, events, eventsLoaded]);

  return (
    <div className="calendar">
      <div className="header">
        <ArrowButton direction={'left'}  onClick={() => {handleMonthChange(undefined, undefined, undefined, -1)}}/>
        <div className="fs-4 fw-bold text-muted prevent-select">{title}</div>
        <ArrowButton direction={'right'} onClick={() => {handleMonthChange(undefined, undefined, undefined, 1)}}/>
      </div>
      <div className="days-of-week">
        <div className="day-of-week">Dom</div>
        <div className="day-of-week">Seg</div>
        <div className="day-of-week">Ter</div>
        <div className="day-of-week">Qua</div>
        <div className="day-of-week">Qui</div>
        <div className="day-of-week">Sex</div>
        <div className="day-of-week">Sáb</div>
      </div>
      <div className="weeks">{renderDays()}</div>
      {children}
      {/* <CalendarVisit
        name="Fulano de Tal"
        goBack={() => { }}
        visible={true}
      /> */}
    </div>
  );
};

export default Calendar;
