import NavBar from "../../components/navbar/navbar"
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import UserInitialCircle from "../../components/userInnitial/userinitial";
import useAxios from "../../services/hooks/useAxios";
import { useDispatch } from "react-redux";
import { setLoading } from "../../services/slices/loadingSlice";
import { useSelector } from "react-redux";
import useGetUserProfile from "../../services/hooks/useGetUserProfile";
import { setLoggedUser } from "../../services/slices/selectionsSlice";

import './userform.css'

export default function UserForm({utils}) {

    const navigate = useNavigate();
    const [profilePic, setProfilePic] = useState(null);
    const [profile] = useGetUserProfile();
    const loggedUser = useSelector((state) => state.selections.loggedUser);
    const axios = useAxios();
    
    const dispatch = useDispatch();

    useEffect(() => {
        if(!profile) {
            dispatch(setLoading(true));  
            return;               
        } else {
            if(!profilePic){
                setProfilePic(profile.profile_picture);
            }
            dispatch(setLoading(false));
        }
    }, [profile, profilePic, dispatch])


    function submitForm(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const dict = Object.fromEntries(data.entries());
        dict.profile_picture = profilePic || profile.profile_picture;
        dispatch(setLoading(true));

        axios.put('users/profile', dict)
            .then((response) => {                
                utils.showAlert('Perfil atualizado com sucesso!', 'ok', () => { utils.hideAlert();; navigate('/home') });
                dispatch(setLoading(false));
                const user = {
                    name: dict.name,
                    email: loggedUser.email,
                    profile_picture: dict.profile_picture,
                    discord_user: loggedUser.discord_user
                }
                dispatch(setLoggedUser(user));
            })
            .catch((error) => {
                dispatch(setLoading(false));
                utils.showAlert('Erro ao atualizar Perfil!', 'error', () => { utils.hideAlert();; navigate('/home') });

            })
    }
    
    return (
        <div>
            <NavBar />
            {profile !== undefined ?            
                <div className='box centralizeItems centerscreen'>

                        <p className="homeTitle mb-3"> Seu perfil</p>
                        <UserInitialCircle 
                            id={1}
                            name={profile?.name} 
                            dropdown={false} 
                            size={'large'}
                            allowUpload={true}
                            changePhoto={(photo) => {
                                dispatch(setLoading(true));
                                axios.post('image/compress', {'image': photo}).then((response) => {
                                    setProfilePic(response.data.image);
                                    dispatch(setLoading(false));
                                }).catch((error) => {
                                    // console.log(error);
                                    dispatch(setLoading(false));
                                })
                            }}
                            photo={profilePic}
                        />
                        <Form 
                            className='mt-3 formLimit' 
                            onSubmit={submitForm} 
                            style={{ margin: '15px', width: '500px' }}
                        >
                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control 
                                    defaultValue={profile.name} 
                                    required name='name' 
                                    type="text" 
                                    placeholder="Seu nome" 
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Label>E-mail</Form.Label>
                                <Form.Control 
                                    defaultValue={profile.email} 
                                    required 
                                    readOnly={true} 
                                    name='email' 
                                    type='email' 
                                    placeholder="Insira seu email" 
                                />
                            </Form.Group>
                            <div className='centralizeItems'>
                                <button 
                                    ype='submit' 
                                    className='btnBlue formBtnSize'
                                > 
                                    Atualizar 
                                </button>
                            </div>
                        </Form>

                </div>
                :
                <div/>
            }
        </div>
    )
}