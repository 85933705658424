import { createSlice } from "@reduxjs/toolkit";

export const deleteModalSlice = createSlice({
    name: "deleteModal",
    initialState: {
        visible: false,
    },
    reducers: {
        setDeleteModalVisible: (state, action) => {
            state.visible = action.payload;
        },
    },
});

export const { setDeleteModalVisible } = deleteModalSlice.actions;

export default deleteModalSlice.reducer;