import { useState, useEffect, useRef } from 'react';
import { firstNames } from '../../services/functions';
import { useNavigate } from 'react-router-dom';
import { clearTokens } from '../../services/slices/authSlice';
import { useDispatch } from 'react-redux';

import './userinitial.css';

export default function UserInitialCircle({name, photo, size, dropdown, id, allowUpload, changePhoto}) {
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const [initials, setInitials] = useState('');
    const circleRef = useRef(null);
    const [visiblePhoto, setVisiblePhoto] = useState(undefined);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    function logout() {
        localStorage.clear()
        dispatch(clearTokens())        
        navigate('/')
    }

    function gotoProfile() {
        navigate('/profile')
    }

    useEffect(() => {
        if (name) {
            setInitials(firstNames(name).split(' ').map((n) => n[0]).join(''))
        } else {
            setInitials('')
        }
        if (dropdown === false) {
            circleRef.current.classList.add('nondropdown');
        }
        if(!visiblePhoto) {
            setVisiblePhoto(photo)
        } 
        // else {
        //     const imgobj = new Image();
        //     imgobj.src = 'data:image/gif;base64,' + visiblePhoto;
        //     // get dimensions of image
        //     imgobj.onload = function () {
        //         console.log('width: ' + imgobj.naturalWidth + ' height: ' + this.naturalHeight)
        //     }
        // }
    }, [initials, name, photo, dropdown, visiblePhoto])

    function handleDropDownClick(e) {
        if (dropdown === true) {
            e.preventDefault();
            e.stopPropagation();
            if (dropdown === false) {
                return;
            }
            setDropDownVisible(!dropDownVisible);
        }
    }

    function getSizeVariant() {
        if (!size) {
            return 'circleDiv'
        } else if (size === 'small') {
            return 'circleDiv small'
        } else if (size === 'medium') {
            return 'circleDiv medium'
        } else if (size === 'large') {
            return 'circleDiv large'
        }
    }

    function handleReaderLoaded (readerEvt)  {
        let binaryString = readerEvt.target.result;
        const img = btoa(binaryString);
        if (changePhoto) {
            changePhoto(img)
            setVisiblePhoto(img)
        }
    }    

    function handleFileChange(e) {

        let file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = handleReaderLoaded
            reader.readAsBinaryString(file)
        }
    }

    return (
        <div className='prevent-select'>
            <label htmlFor={allowUpload === true? "file-upload" + id : ""} className={getSizeVariant()}>
                <div ref={circleRef} onClick={handleDropDownClick} data-bs-toggle="tooltip" data-bs-placement="top" title={name}>
                    {visiblePhoto ?
                        <img className={'profilePicture ' + getSizeVariant()} src={'data:image/gif;base64,' + visiblePhoto} alt='Foto de perfil' />
                        :
                        <div>
                            {initials || ""}
                        </div>
                    }
                </div>
            </label>
            <input onChange={handleFileChange} id={"file-upload" + id } type="file" accept='image/jpeg' />
            {dropDownVisible && (
                <div className="dd">
                    <div className='horizontalAlign'>
                        <p className='profileName'>{name}</p>
                    </div>
                    <hr className='styledHr' />
                    <div className='verticalAlign' style={{ width: '100%', marginTop: '10px' }}>
                        <button onClick={gotoProfile} className='btnWhite noMarginsBtnDd'> Perfil</button>
                        <button onClick={logout} className='btnWhite noMarginsBtnDd hoverRed'>Sair</button>
                    </div>
                </div>
            )}

        </div>

    )
}