import { createSlice } from '@reduxjs/toolkit'

export const userListSlice = createSlice({
    name: 'userList',
    initialState: {
        users: [],
        userListVisible: false,
        title: "Ver calendário de:"
    },
    reducers: {
        setUsersStore: (state, action) => {
            state.users = action.payload
        },
        setUserListVisible: (state, action) => {
            state.userListVisible = action.payload
        },
        setTitle: (state, action) => {
            state.title = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUsersStore, setUserListVisible, setTitle } = userListSlice.actions

export default userListSlice.reducer