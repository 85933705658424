import Backdrop from '../backdrop/Backdrop';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import './loading.css'

export default function Loading(props) {

    function conditionalRender(){
        if(props.visible){
            return (
                <Backdrop visible={props.visible}>
                <div className='loading'>
                    <AiOutlineLoading3Quarters className='icon-spin' style={{fontSize: '60px', color: 'white'}} />
                    <p className='loadingText'>carregando</p>
                </div>            
                </Backdrop>
            );
        }
        return <div></div>;
    }

    return conditionalRender()

    
}