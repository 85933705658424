import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setDeleteModalVisible } from '../../services/slices/deleteModalSlice';
import { setSelectedAppointment } from '../../services/slices/selectionsSlice';
import { setEventsLoaded } from '../../services/slices/useEventsSlice';
import useAxios from '../../services/hooks/useAxios';

function DeleteConfirmForm() {
    const [selectedOption, setSelectedOption] = useState('this');
    const dispatch = useDispatch();
    const axios = useAxios();

    const handleClose = () => {
        dispatch(setDeleteModalVisible(false));
    }

    const modalVisible = useSelector((state) => state.deleteModal.visible);
    const selectedAppointment = useSelector((state) => state.selections.selectedAppointment);

    useEffect(() => {
        setSelectedOption('this')
    }, [modalVisible]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(setEventsLoaded(true));
        axios.delete(`/appointments/${selectedAppointment.id}`, { params: { delete_strategy: selectedOption } }).then((response) => {
            dispatch(setEventsLoaded(false));
            dispatch(setDeleteModalVisible(false));
            dispatch(setSelectedAppointment(null));
        }).catch((error) => {

        });
    };

    return (
        <>
            <Modal className='z-2' backdrop='static' show={modalVisible} centered dialogClassName='dialogSize'>
                <Modal.Header>
                    <p className='formTitle m-0 p-0 prevent-select'>Como você gostaria de excluir?</p>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Check
                            type="radio"
                            label="Deletar este evento"
                            name="formRadios"
                            id="formRadios1"
                            checked={selectedOption === 'this'}
                            onChange={() => setSelectedOption('this')}
                        />
                        <Form.Check
                            type="radio"
                            label="Deletar este e todos os eventos futuros"
                            name="formRadios"
                            id="formRadios2"
                            checked={selectedOption === 'next'}
                            onChange={() => setSelectedOption('next')}
                        />
                        <Form.Check
                            type="radio"
                            label="Deletar todos os eventos"
                            name="formRadios"
                            id="formRadios3"
                            checked={selectedOption === 'all'}
                            onChange={() => setSelectedOption('all')}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex mx-auto w-100'>
                        <button type="submit" onClick={handleSubmit} className="btnBlue formBtnSize p-0 m-0 mx-auto">
                            OK
                        </button>
                        <button onClick={handleClose} className="btnBorderlessRed formBtnSize p-0 m-1 mb-0 mt-0">
                            Cancelar
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteConfirmForm;